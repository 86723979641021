import React from "react";
import { Footer } from "../components/footer";
import Transition from "../utils/Transition"; // Nếu bạn có component Transition
import parallelSessionImage from "../components/assests/parallel-session.png"; // Import hình ảnh Parallel Session

const ConferenceProgram = () => {
  return (
    <Transition>
      <div className="flex flex-col min-h-screen bg-gray-50">
        {/* Nội dung chính */}
        <div className="flex-grow my-20 px-4 lg:px-36">
          <div className="bg-white shadow-xl rounded-lg p-8">
            <div className="text-center">
              <h1 className="text-3xl font-bold mb-6 text-gray-800 leading-tight">
                The 2024 Conference on Applications of Technology, Automation,
                and Civil Engineering
              </h1>
              <h2 className="text-2xl text-indigo-600 mb-8 font-semibold">
                ATAC 2024 AGENDA
              </h2>
              <div className="text-xl text-gray-700 space-y-4 mb-8">
                <p>
                  <strong>Time:</strong> 07h30 – 13h00, November 23, 2024
                </p>
                <p>
                  <strong>Venue:</strong> Hall B, Saigon Technology University (STU),
                  180 Cao Lỗ, Phường 4, Quận 8, TP.HCM
                </p>
              </div>
              {/* Thêm lịch trình chi tiết */}
              <div className="text-left">
                <h3 className="text-2xl font-bold mb-4">
                  PLENARY SESSION - MAIN HALL
                </h3>

                <table className="w-full text-gray-700">
                  <tbody>
                    <tr className="border-b">
                      <td className="py-2 align-top w-1/4">
                        <strong>7:30 - 8:00</strong>
                      </td>
                      <td className="py-2">Guest Registration</td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>8:00 - 8:15</strong>
                      </td>
                      <td className="py-2">Opening Remarks</td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>8:15 - 8:25</strong>
                      </td>
                      <td className="py-2">
                        Welcome Speech by Associate Professor Hoang Kim Anh,
                        Vice President of Saigon Technology University
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>8:25 - 8:35</strong>
                      </td>
                      <td className="py-2">
                        Welcome Speech by Representative of AIoT Lab VN,
                        Co-Chair
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>8:35 - 8:45</strong>
                      </td>
                      <td className="py-2">
                        Welcome Speech by Representative of the Youth
                        Development Science and Technology Center (TST)
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>8:45 - 9:00</strong>
                      </td>
                      <td className="py-2">
                        Presentation of Flowers and Photo Session
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>9:00 - 9:45</strong>
                      </td>
                      <td className="py-2">
                        Keynote Speaker 1, Assoc. Prof. Razvan Beuran,
                        "Applications of AI/ML to Cybersecurity"
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>9:45 - 10:15</strong>
                      </td>
                      <td className="py-2">
                        Keynote Speaker 2, Dr. Huynh Vo Trung Dung, "The Synergy
                        of Blockchain and AI in 6G Networks: Opportunities,
                        Challenges, and Prospective Pathways"
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>10:15 - 10:45</strong>
                      </td>
                      <td className="py-2">
                        Keynote Speaker 3, Ms. Ta Thuy Trang, "Machine Learning
                        Algorithms for Predicting Compressive Strength of
                        Concrete: A Case Study in Binh Thuan, Viet Nam"
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>10:45 - 11:00</strong>
                      </td>
                      <td className="py-2">Tea Break</td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 align-top">
                        <strong>11:00 - 12:30</strong>
                      </td>
                      <td className="py-2">Parallel Session</td>
                    </tr>
                    <tr>
                      <td className="py-2 align-top">
                        <strong>12:30 - 12:45</strong>
                      </td>
                      <td className="py-2">Closing Remarks</td>
                    </tr>
                  </tbody>
                </table>
                <h3 className="pt-5 text-2xl font-bold mb-4">
                  PARALLEL SESSION
                </h3>
                <a
                  href="https://docs.google.com/spreadsheets/d/1_QVl2NnGSNiMOllaYdD87z4Lsi0b0ySb/edit?usp=sharing&ouid=103350968379968901210&rtpof=true&sd=true"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue_super_dark font-bold hover:underline mb-4 block"
                >
                  Download Parallel Session Schedule (Excel)
                </a>
                <img
                  src={parallelSessionImage}
                  alt="Parallel Session Schedule"
                  className="max-w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </div>
    </Transition>
  );
};

export default ConferenceProgram;
